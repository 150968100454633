import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import img1 from "../assets/img/posters/1.webp";
import img2 from "../assets/img/posters/2.webp";
import img3 from "../assets/img/posters/3.webp";
import img4 from "../assets/img/posters/4.webp";
import img5 from "../assets/img/posters/5.webp";
import img6 from "../assets/img/posters/6.webp";
import img7 from "../assets/img/posters/7.webp";
import img8jpg from "../assets/img/posters/8.webp";

import pdfa1 from "../assets/pdf/EP_Wanderausstellung_Plakate_A1.pdf";
import pdfa3 from "../assets/pdf/EP_Wanderausstellung_Plakate_A3.pdf";

import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "../scss/poster.scss";

import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import EventManager from "../js/EventManager";
const Poster = () => {
  useEffect(() => {}, []);
  const [posterOpen, setPosterOpen] = React.useState(false);
  const [activeIndex, setActiveIndex] = React.useState(Number);
  const [position, setPosition] = React.useState<"top" | "bottom" | "start" | "end">("bottom");
  const [width, setWidth] = React.useState(120);
  const [height, setHeight] = React.useState(80);
  const [border, setBorder] = React.useState(1);
  const [borderRadius, setBorderRadius] = React.useState(4);
  const [padding, setPadding] = React.useState(4);
  const [gap, setGap] = React.useState(16);
  const [preload, setPreload] = React.useState(2);
  const [showToggle, setShowToggle] = React.useState(false);
  const handleOpenLightbox = (index: number) => {
    setActiveIndex(index);
    setPosterOpen(true);
  };

  const slidesArr = [
    { src: img1 },
    { src: img2 },
    { src: img3 },
    { src: img4 },
    { src: img5 },
    { src: img6 },
    { src: img7 },
    { src: img8jpg },
  ];
  EventManager.subscribe("ausstellungClicked", handleOpenLightbox);

  return (
    <>
      <Lightbox
        open={posterOpen}
        close={() => setPosterOpen(false)}
        index={activeIndex}
        slides={slidesArr}
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
        carousel={{ finite: true, preload: 10, padding: 0, imageFit: "contain" }}
        thumbnails={{
          position: "bottom",
          width: 41,
          height: 124,
          border: 0,
          borderRadius: 4,
          padding: 0,
          gap: 16,
          showToggle: false,
        }}
      />

      <Container fluid id="ausstellung">
        <Row className="gx-0 row flex-wrap">
          <Col key="1" className="custom-col" onClick={() => handleOpenLightbox(0)}>
            <img src={img1} alt="" />
          </Col>
          <Col key="2" className="custom-col" onClick={() => handleOpenLightbox(1)}>
            <img src={img2} alt="" />
          </Col>
          <Col key="3" className="custom-col" onClick={() => handleOpenLightbox(2)}>
            <img src={img3} alt="" />
          </Col>
          <Col key="4" className="custom-col" onClick={() => handleOpenLightbox(3)}>
            <img src={img4} alt="" />
          </Col>
          <Col key="5" className="custom-col" onClick={() => handleOpenLightbox(4)}>
            <img src={img5} alt="" />
          </Col>
          <Col key="6" className="custom-col" onClick={() => handleOpenLightbox(5)}>
            <img src={img6} alt="" />
          </Col>
          <Col key="7" className="custom-col" onClick={() => handleOpenLightbox(6)}>
            <img src={img7} alt="" />
          </Col>
          <Col key="8" className="custom-col" onClick={() => handleOpenLightbox(7)}>
            <img src={img8jpg} alt="" />
          </Col>
        </Row>

        <p className="mt-5 mb-0">
          Du kannst dir die Ausstellung auch als <strong>Plakatversion</strong> zum Ausdrucken herunterladen oder dich über unseren{" "}
          <strong>digitalen Wahlflyer</strong> informieren.
        </p>
        <Row className="m-2 mb-5 d-flex justify-content-center gy-3 gx-2">
          <Col xs={12} lg={2} className="text-center">
            <Button target="_blank" href={pdfa3} className="btn-lg">
              Plakate DIN-A3
            </Button>
          </Col>
          <Col xs={12} lg={2} className="text-center">
            <Button href={pdfa1} target="_blank" className="btn-lg">
              Plakate DIN-A1
            </Button>
          </Col>
          <Col xs={12} lg={2} className="text-center">
            <Button className="btn-lg">
              <a
                href="https://digitaler-wahlflyer.eu-on-tour.eu"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "inherit", textDecoration: "inherit" }}
              >
                Digitaler Wahlflyer
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Poster;
