import { useEffect } from "react";
import { Link } from "react-router-dom";

import { Navbar, Nav, Container } from "react-bootstrap";
import logo_ep from "../assets/img/logos/EP_Logo_EP.jpg";

import "../scss/footer.scss";

const Footer = () => {
  useEffect(() => {}, []);
  return (
    <>
      <Navbar expand="lg" className="">
        <Container>
          <Navbar.Brand className="col-md-6" href="#home">
            <img src={logo_ep} alt="Die Flagge der Europäischen Union." /> Ein Angebot des Europäischen Parlaments
          </Navbar.Brand>
          <Nav.Link href="/impressum">Impressum</Nav.Link>
          <Nav.Link href="/datenschutz">Datenschutz</Nav.Link>
        </Container>
      </Navbar>
    </>
  );
};
export default Footer;
