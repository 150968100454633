import { useEffect } from "react";

import "../scss/information.scss";

const Information = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="information " id="info">
        <div>
          <h2>EU on tour – Die Wanderausstellung zur Europawahl</h2>
        </div>{" "}
        <div className="container-lg">
          <p>
            Am <strong>9. Juni 2024</strong> fand die <strong>Europawahl in Deutschland</strong> statt. In den Wochen davor hat die
            Wanderausstellung des Verbindungsbüros des Europäischen Parlaments in Deutschland an knapp 100 Schulen und weiteren
            Institutionen Halt gemacht, um Erst- und Jungwählerinnen und -wähler über die Europawahl und die Aufgaben des Europäischen
            Parlaments zu informieren. Wir haben uns sehr über das große Interesse gefreut und hoffen, dass viele Erstwählerinnen und
            Erstwähler ihre Stimme genutzt haben.
          </p>
          <p>
            Informationen zu den Wahlergebnissen findest du unter{" "}
            <a target="_blank" href="http://www.europawahl.eu" rel="noreferrer">
              www.europawahl.eu
            </a>
            .<br />
            Welche Abgeordnete aus Deutschland ins Europäische Parlament einziehen, erfährst du auf der{" "}
            <a target="_blank" href="http://www.bundeswahlleiterin.de/europawahlen/2024/gewaehlte.html" rel="noreferrer">
              Webseite der Bundeswahlleiterin
            </a>
            .
          </p>
          <p>Gerne stellen wir dir auch nach der Europawahl 2024 die digitale Version unserer Wanderausstellung zur Verfügung!</p>
        </div>
      </div>
    </>
  );
};
export default Information;
