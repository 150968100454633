import React, { useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import EventManager from "../js/EventManager";
import logo from "../assets/img/logos/EE24_SIGNATURE_CMYK_negative_DE.png";

import pdfab from "../assets/pdf/EP_Wanderausstellung_Begleitmaterial.pdf";

import "../scss/header.scss";

const NavBar = () => {
  function debounce(func: (this: any, ...args: any[]) => void, wait: number, immediate: boolean = false): (...args: any[]) => void {
    let timeout: number | undefined;

    return function (this: any, ...args: any[]) {
      const context = this;
      const later = () => {
        timeout = undefined;
        if (!immediate) func.apply(context, args);
      };

      const callNow = immediate && timeout === undefined;
      clearTimeout(timeout as number);
      timeout = window.setTimeout(later, wait);

      if (callNow) {
        func.apply(context, args);
      }
    };
  }

  useEffect(() => {
    const changeNavbar = debounce(function () {
      const navbar: HTMLElement | null = document.getElementById("home");
      if (navbar) {
        if (window.scrollY > 50) {
          navbar.classList.add("small-navbar");
          navbar.classList.remove("large-navbar");
        } else {
          navbar.classList.add("large-navbar");
          navbar.classList.remove("small-navbar");
        }
      }
    }, 100);
    window.addEventListener("scroll", changeNavbar as EventListener);
  }, []);

  function handleClick() {
    console.log("clicked");

    EventManager.publish("ausstellungClicked");
  }
  return (
    <>
      <Navbar id="home" expand="lg" className="fixed-top sticky-top large-navbar">
        <Container>
          <Navbar.Brand href="/#home">
            <img className="" src={logo} alt="Das Logo der Wahlbeteiligungskampagne 'Mach mit!'" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link onClick={handleClick} href="/#ausstellung">
                Digitale Ausstellung
              </Nav.Link>
              <Nav.Link target="_blank" href={pdfab}>
                Begleitmaterial
              </Nav.Link>
              <Nav.Link href="/#infomaterialien">Weitere Infos</Nav.Link>
              <Nav.Link href="/#kontakt">Kontakt</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};
export default NavBar;
