import NavBar from "./NavBar";
import Footer from "./Footer";
import "../scss/about.scss";

const DataPrivacy = () => {
  return (
    <>
      <NavBar></NavBar>
      <div className="frame-inner container-lg metapage pt-5 pb-5">
        <div className="frame-header">
          <h2 className="element-header  ">
            <span>DATENSCHUTZERKLÄRUNG</span>
          </h2>

          <h3 className="element-subheader  ">
            <span>Valentum Kommunikation GmbH</span>
          </h3>
        </div>

        <p>
          Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der Erforderlichkeit sowie zum Zwecke der Bereitstellung
          eines funktionsfähigen und nutzerfreundlichen Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
        </p>
        <p>
          Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“
          jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen Daten,
          wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die
          Verwendung, die Offenlegung durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
          Einschränkung, das Löschen oder die Vernichtung.
        </p>
        <p>
          Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck, Dauer und Rechtsgrundlage der Verarbeitung
          personenbezogener Daten, soweit wir entweder allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem
          informieren wir Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität eingesetzten Fremdkomponenten,
          soweit hierdurch Dritte Daten in wiederum eigener Verantwortung verarbeiten.
        </p>
        <h2>
          <strong>I. Informationen über uns als Verantwortliche</strong>
        </h2>
        <p>Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:</p>
        <p>
          Valentum Kommunikation GmbH
          <br /> Bischof-von-Henle Straße 2b
          <br /> 93051 Regensburg
          <br /> Deutschland
          <br /> 0941 591896 10
          <br />{" "}
          <a href="#" data-mailto-token="ocknvq,kphqBxcngpvwo/mqoowpkmcvkqp0fg" data-mailto-vector="2">
            info@valentum-kommunikation.de
          </a>
          <br />{" "}
          <a href="https://www.valentum-kommunikation.de/" target="_blank" data-htmlarea-external="1" rel="noreferrer">
            www.valentum-kommunikation.de
          </a>
        </p>
        <p>Der Datenschutzbeauftragte des Verantwortlichen ist:</p>
        <p>
          Franziska Otto
          <br /> Bischof-von-Henle Straße 2b
          <br /> 93051 Regensburg
          <br /> Deutschland
          <br />{" "}
          <a href="#" data-mailto-token="ocknvq,fcvgpuejwvbBxcngpvwo/mqoowpkmcvkqp0fg" data-mailto-vector="2">
            datenschutz@valentum-kommunikation.de
          </a>
        </p>
        <h2>
          <br /> <strong>II. Rechte der Nutzer und Betroffene</strong>
        </h2>
        <p>Im Folgenden informieren wir sie über ihre Rechte in Bezug auf ihre Daten.</p>
        <h3>
          <strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong>
        </h3>
        <p>
          Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist
          jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung
          bleibt vom Widerruf unberührt.
        </p>
        <h3>
          <strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong>
        </h3>
        <p>
          Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige
          Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist das Bayerisches Landesamt für Datenschutzaufsicht, Promenade 27, 91522 Ansbach
        </p>
        <h3>
          <strong>Recht auf Datenübertragbarkeit</strong>
        </h3>
        <p>
          Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an
          Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen
          anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.
        </p>
        <h3>
          <strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong>
        </h3>
        <p>
          Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen
          Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser
          Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die Mailadresse{" "}
          <a href="#" data-mailto-token="ocknvq,fcvgpuejwvbBxcngpvwo/mqoowpkmcvkqp0fg" data-mailto-vector="2">
            datenschutz@valentum-kommunikation.de
          </a>{" "}
          uns wenden.
        </p>
        <h2>
          <br /> <strong>III. Informationen zur Datenverarbeitung</strong>
        </h2>
        <p>
          Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt, der Löschung
          der Daten keine gesetzlichen Aufbewahrungspflichten entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren
          gemacht werden.
        </p>
        <h3>
          <strong>SSL- bzw. TLS-Verschlüsselung</strong>
        </h3>
        <p>
          Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine
          SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte
          Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.
        </p>
        <h3>
          <strong>Serverdaten</strong>
        </h3>
        <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt.</p>
        <p>Dies sind in der Regel:</p>
        <ul>
          {" "}
          <li>Browsertyp und Browserversion</li> <li>Verwendetes Betriebssystem</li> <li>Referrer URL</li> <li>Uhrzeit der Serveranfrage</li>{" "}
          <li>IP-Adresse</li>{" "}
        </ul>
        <p>Die erfolgt aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen Internetauftritts.</p>
        <p>Diese so erhobenen Daten werden vorrübergehend gespeichert und nach einem Monat automatisiert gelöscht.</p>
        <h3>
          <strong>Kontakt- und Anmeldeformulare</strong>
        </h3>
        <p>
          Per Kontakt- Anmeldeformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für
          Anschlussfragen bereitzustehen. Eine Weitergabe der Kontaktformulardaten an Dritte findet nicht statt. Eine Weitergabe der Anmeldeformulardaten findet
          nur statt, inwieweit diese zum Zweck der Anmeldung an Dritte weitergegeben werden müssen.
        </p>
        <p>
          Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die
          Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Über das Kontaktformular übermittelte Daten
          verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung
          mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
        </p>
        <h3>
          <strong>Cookies</strong>
        </h3>
        <p>
          Unsere Website verwendet Cookies. Das sind kleine Textdateien, die Ihr Webbrowser auf Ihrem Endgerät speichert. Cookies helfen uns dabei, unser
          Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
        </p>
        <ol>
          {" "}
          <li>a) Sitzungs-Cookies/Session-Cookies</li>{" "}
        </ol>
        <p>
          Einige Cookies sind “Session-Cookies.” Durch diese Cookies werden im individuellen Umfang bestimmte Informationen von Ihnen, wie beispielsweise Ihre
          Browser- oder Standortdaten oder Ihre IP-Adresse, verarbeitet. Durch diese Verarbeitung wird unser Internetauftritt benutzerfreundlicher, effektiver
          und sicherer.
        </p>
        <p>
          Rechtsgrundlage dieser Verarbeitung ist Art. 6 Abs. 1 lit b.) DSGVO, sofern diese Cookies Daten zur Vertragsanbahnung oder Vertragsabwicklung
          verarbeitet werden.
        </p>
        <p>
          Falls die Verarbeitung nicht der Vertragsanbahnung oder Vertragsabwicklung dient, liegt unser berechtigtes Interesse in der Verbesserung der
          Funktionalität unseres Internetauftritts. Rechtsgrundlage ist in dann Art. 6 Abs. 1 lit. f) DSGVO.
        </p>
        <p>Solche Cookies werden nach Ende Ihrer Browser-Sitzung von selbst gelöscht.</p>
        <ol>
          {" "}
          <li>b) Drittanbieter-Cookies</li>{" "}
        </ol>
        <p>
          Gegebenenfalls werden mit unserem Internetauftritt auch Cookies von Partnerunternehmen, mit denen wir zum Zwecke der Werbung, der Analyse oder der
          Funktionalitäten unseres Internetauftritts zusammenarbeiten, verwendet.
        </p>
        <p>
          Diese Cookies bleiben auf Ihrem Endgerät bestehen, bis Sie diese selbst löschen. Solche Cookies helfen uns, Sie bei Rückkehr auf unserer Website
          wiederzuerkennen.
        </p>
        <ol>
          {" "}
          <li>c) Beseitigungsmöglichkeit</li>{" "}
        </ol>
        <p>
          Mit einem modernen Webbrowser können Sie das Setzen von Cookies überwachen, einschränken oder unterbinden. Viele Webbrowser lassen sich so
          konfigurieren, dass Cookies mit dem Schließen des Programms von selbst gelöscht werden. Die hierfür erforderlichen Schritte und Maßnahmen hängen
          jedoch von Ihrem konkret genutzten Internet-Browser ab. Bei Fragen benutzen Sie daher bitte die Hilfefunktion oder Dokumentation Ihres
          Internet-Browsers oder wenden sich an dessen Hersteller bzw. Support.
        </p>
        <p>Die Deaktivierung von Cookies kann eine eingeschränkte Funktionalität unserer Website zur Folge haben.</p>
        <a href="/">Zurück</a>
      </div>
      <Footer></Footer>
    </>
  );
};
export default DataPrivacy;
