import React, { useEffect } from "react";

import Card from "react-bootstrap/Card";
import pdf_europawahl2024 from "../assets/pdf/uebersicht_wahlmodalitaeten.pdf";
import pdfab from "../assets/pdf/EP_Wanderausstellung_Begleitmaterial.pdf";

import "../scss/information.scss";

const Infomaterial = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="information" id="infomaterialien">
        <h2>Weitere Infos und Angebote</h2>
        <div className="container-lg">
          <p>Du möchtest noch mehr erfahren? Hier haben wir interessante Links und Angebote für dich gesammelt.</p>
        </div>
        <div className="container container-lg">
          <div className="row gx-3 gy-3">
            <Card className="col-md-3 d-flex flex-column">
              <a href={pdfab} className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>Begleitmaterial zur Ausstellung</Card.Title>
                  <Card.Text>
                    Hier findest du nützliche Informationen zur Ausstellung und Anregungen zu weiterführenden Themen und Angeboten, die zum
                    Beispiel im Unterricht oder bei Veranstaltungen genutzt werden können.
                  </Card.Text>
                  <Card.Link href="#">Download</Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>{" "}
            <Card className="col-md-3 d-flex flex-column">
              <a href="https://www.europawahl.eu" className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>Europawahl 2024</Card.Title>
                  <Card.Text>
                    Hier könnt ihr euch die Ergebnisse der Europawahl in allen Mitgliedstaaten anschauen. Außerdem könnt ihr noch einmal
                    nachlesen, wie die Wahl funktioniert und wie es jetzt nach der Wahl weitergeht.
                  </Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href="https://www.europawahl.eu">
                    www.europawahl.eu
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a href={pdf_europawahl2024} className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>Übersicht Wahlmodalitäten</Card.Title>
                  <Card.Text>Hier kannst du dir eine Übersicht zu den Wahlmodalitäten in den Mitgliedstaaten herunterladen.</Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href={pdf_europawahl2024}>
                    Download
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a href="https://gemeinsamfuer.eu/de/" className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>gemeinsamfuer.eu</Card.Title>
                  <Card.Text>
                    Unser überparteiliches und pro-europäisches Netzwerk gemeinsamfuer.eu: Vernetze dich mit anderen Europa-Interessierten,
                    nimm an Workshops, Trainings und Abgeordnetengesprächen teil und organisiere als Volunteer deine eigene Veranstaltung.
                  </Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href="https://gemeinsamfuer.eu/de/">
                    www.gemeinsamfuer.eu
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a href="https://virtual-role-play-game.digital-journey.europarl.europa.eu/#/" className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>Virtuelles Rollenspiel</Card.Title>
                  <Card.Text>
                    Unser virtuelles Rollenspiel kannst du mit einer Gruppe von 12 bis 40 Personen spielen, die in die Rolle von
                    Abgeordneten des Europäischen Parlaments schlüpfen und über wichtige EU-Vorschriften abstimmen.
                  </Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href="https://virtual-role-play-game.digital-journey.europarl.europa.eu/#/">
                    virtual-role-play-game.digital-journey.europarl.europa.eu
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a
                href="https://visiting.europarl.europa.eu/de/visitor-offer/other-locations/europa-experience/berlin"
                className="cardlink"
                target="_blank"
              >
                <Card.Body>
                  <Card.Title>ERLEBNIS EUROPA</Card.Title>
                  <Card.Text>
                    Wenn du in Berlin bist, besuche gerne unsere Multimedia-Ausstellung Erlebnis Europa und schaue dich in unserem 360°-Kino
                    um oder mache ein Foto in unserer neuen Fotobox!
                  </Card.Text>
                  <Card.Link
                    target="_blank"
                    rel="noreferrer"
                    href="https://visiting.europarl.europa.eu/de/visitor-offer/other-locations/europa-experience/berlin"
                  >
                    visiting.europarl.europa.eu/
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a href="https://survey.alchemer.eu/s3/90238740/EPLO-Berlin" className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>Infomaterial bestellen</Card.Title>
                  <Card.Text>
                    Manche Sachen braucht man schwarz auf weiß: Wir haben viele Informationsmaterialien, die du herunterladen oder dir auf
                    Papier zuschicken lassen kannst.
                  </Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href="https://survey.alchemer.eu/s3/90238740/EPLO-Berlin">
                    survey.alchemer.eu
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
            <Card className="col-md-3 d-flex flex-column">
              <a href="https://youth.europa.eu/discovereu_de" className="cardlink" target="_blank">
                <Card.Body>
                  <Card.Title>DiscoverEU</Card.Title>
                  <Card.Text>
                    Möchtest du andere europäische Länder kennenlernen? Dann ist DiscoverEU das Richtige für dich. Dort kannst du ein
                    Interrail-Ticket gewinnen und dich mit dem Zug auf den Weg durch Europa machen!
                  </Card.Text>
                  <Card.Link target="_blank" rel="noreferrer" href="https://youth.europa.eu/discovereu_de">
                    youth.europa.eu
                  </Card.Link>
                </Card.Body>{" "}
              </a>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default Infomaterial;
