import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import "./scss/meta.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import Information from "./components/Information";
import Infomaterial from "./components/Infomaterial";
import Poster from "./components/Poster";
import WhiteSpace from "./components/WhiteSpace";
import DataPrivacy from "./components/DataPrivacy";
import Imprint from "./components/Imprint";
import Kontakt from "./components/Kontakt";

//import reportWebVitals from "./reportWebVitals";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/impressum" element={<Imprint />} />
        <Route path="/datenschutz" element={<DataPrivacy />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

const Home = () => {
  return (
    <React.StrictMode>
      <NavBar />
      <Information />
      <Poster />
      <WhiteSpace />
      <Infomaterial />
      <Kontakt />
      <Footer />
    </React.StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
