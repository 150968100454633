import React, { useEffect } from "react";

import "../scss/kontakt.scss";

const Kontakt = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="information" id="kontakt">
        <div className="container-lg">
          <h2>Kontakt für Fragen</h2>
          <p>Mit Fragen kannst du dich jederzeit gerne an uns wenden.</p>
          <div className="kontaktdaten">
            <p>
              <a href="mailto:eu-on-tour@valentum-kommunikation.de">✉️eu-on-tour@valentum-kommunikation.de</a>
              <span>&nbsp;</span>
              <a href="tel:0941 591896 96">📞0941 591896 96</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Kontakt;
