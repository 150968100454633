import React, { useEffect } from "react";

import "../scss/whitespace.scss";

const WhiteSpace = () => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="whitespace"></div>
    </>
  );
};
export default WhiteSpace;
